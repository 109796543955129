/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
import Rails from "@rails/ujs"
global.Rails = Rails
//import Turbolinks from "turbolinks"

Rails.start()
//Turbolinks.start()

require("jquery-ui")
import "core-js/stable"
import "regenerator-runtime/runtime"
import "../src/scripts"
import "../src/geoxml3"
import "@eastdesire/jscolor"

jscolor.presets.default = {
	format:'any', previewPosition:'right', previewSize:60, 
	borderColor:'rgba(156,156,156,1)', borderRadius:0, padding:6, 
	closeText:'Close Picker', controlBorderColor:'rgba(138,138,138,1)', 
	controlBorderWidth:0, sliderSize:12, crossSize:7, 
	pointerThickness:1, pointerBorderWidth:2, shadow:false, 
	shadowColor:'rgba(255,18,22,0.2)'
};
